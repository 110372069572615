import * as React from "react"
import styled from "styled-components"
import Layout from "../../../components/layout"
import PPLogo from "../../../images/pp/head/tp/p.inline.svg"
import Logo from "../../../images/pp/head/tp/cp_logo.inline.svg"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"
import Projects from "../../../components/pp_projects"
import textpayCJM from "../../../images/pp/textpay_cjm.png"
import Footer from '../../../components/footer';
import screen1 from "../../../images/pp/app.png"
import screen2 from "../../../images/pp/existing_account.png"
import screen3 from "../../../images/pp/spaces.png"
import screen4 from "../../../images/pp/protection.png"

import screen5 from "../../../images/pp/no_lp.png"
import screen6 from "../../../images/pp/auth.png"
import screen7 from "../../../images/pp/lp_by_camera.png"
import screen8 from "../../../images/pp/space_type.png"

import screen9 from "../../../images/pp/oklahoma.png"
import screen10 from "../../../images/pp/peek_travel.png"
import screen11 from "../../../images/pp/review_pay.png"
import screen12 from "../../../images/pp/success.png"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Wrapper from '../../../components/wrap';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

// markup
const IndexPage = () => {
  return (
    <Layout navtype="dark" title="Premium Parking">
      <Header>
        <AnimationOnScroll animateIn="animate__fadeInRight" className="pp-logo" animateOnce="true" offset={0}>
          <PPLogo />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInLeft" className="tp-logo" animateOnce="true" offset={0}>
          <Logo />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeIn" className="camera" animateOnce="true" offset={0}>
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/head/tp/photo.png"
            alt="Photo"
            layout="constrained"
          />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInDown" className="ep" animateOnce="true" offset={0}>
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/head/tp/ep.png"
            alt="Photo"
            layout="constrained"
          />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp" className="lp" animateOnce="true" offset={0}>
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/head/tp/lp.png"
            alt="License Plate"
            layout="constrained"
            />
        </AnimationOnScroll>
      </Header>
      <AnimationOnScroll animateIn="animate__wrapInUp" animateOnce="true">
      <Wrapper className="first">
        <h1>
          <small>Premium Parking</small>
          Rethinking checkout flow for upsell products  in TextPay and CameraPay</h1>
        <ul className="types">
          <li>
            <small>Timeframe</small>
            <br />
            Aug 2021-Jul 2022
          </li>
          <li>
            <small>Role</small>
            <br />
            Lead Product Designer
          </li>
          <li>
            <small>Responsibilities</small>
            <br />
            Research, UX Design, Usability Testing, Development hand-off
          </li>
          <li className="full">
            <small>Team</small>
            <br />
            Company President, Director of Business Intelligence, Director of Product Experience, Lead Product Designer (me), 2 Front-End Developers, Development Team Lead, 2 Back-end Engineers, Project Manager
          </li>
        </ul>
        <br />
        <p>
          <a href="https://premiumparking.com" target="_blank" rel="noreferrer">Premium Parking</a> is digital parking solution in the US, with over 500 employees and $105.7M in annual revenue.
          <a href="https://textpay.premiumparking.com" target="_blank" rel="noreferrer">TextPay</a> and <a href="https://camerapay.premiumparking.com" target="_blank" rel="noreferrer">CameraPay</a> are simplified versions of the web application of Premium Parking, oriented to checkout-flow. Works anywhere with instant launch without any downloads.
        </p>
        <h3>Historical context</h3>
        <p>Development team Umenit launched Textpay in 2016. I didn't work closely with them. Our collaboration was only about auth issues for mobile apps users, if they registered before in TextPay. TextPay customers send a text message with a location number to the SMS gateway. The platform identifies the customer by phone number and sends back a link to a simplified checkout flow.</p>
        <p>Examples of the TextPay before the redesign.</p>
        <div className="history history-mobile">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/textpay_old1.png"
            alt="Map"
          />
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/textpay_old2.png"
            alt="Map"
          />
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/textpay_old3.png"
            alt="Map"
          />
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/textpay_old4.png"
            alt="Map"
          />
        </div>
        <strong className="sub-title">Challenges and goals</strong>
        <h3>Increase revenue by upselling products from partners to customers</h3>
        <ol>We had goals:
          <li>Integration with Travel provider</li>
          <li>Integration with Insurance provider</li>
          <li>Convert customers to loyal clients</li>
          <li>Keep checkout flexible for different rates and products combinations</li>
          <li>Keep consistency with other Premium products</li>
        </ol>
        <p>We wanted to use the same checkout workflow as on a web and mobile apps, which I designed during <Link to="/projects/premium-parking/mobile">redesigning mobile apps</Link>. Time has shown that it is efficient and flexible for different brands and types of parking lots. Additionally, we had most of the components that could be used for the new version of TextPay</p>
        <p>
          Besides, the next big challenge was the launch of a CameraPay that would be a clone of TextPay. The difference between products in auth flows. In CameraPay the process starts with scanning the location's QR Code. So, we know the location number, and we need to identify the customer or not.
          <br />
          <a href="#results">View Results</a>
        </p>

        <strong className="sub-title">My role and the team</strong>
        <h3>Holistic approach for a complex challenge</h3>
        <p>
          The team was well versed in the product, design system, and code base. 
          I was responsible for all design initiatives. I mapped out the user's journey, designed ideas, user validation, iteration, prototyping, and crafted high fidelity prototypes throughout all stages of the project. Also, I directly collaborated with partner's teams to understand integrations requirements.
        </p>
        <strong className="sub-title">Design Process</strong>
        <h3>Research</h3>
        <p><strong>Just suggesting the partner's products for customers during the checkout flow wouldn't bring the 100% desired outcome. It had to be a coordinated effort in multiple phases of the checkout steps.</strong></p>
        <p>I looked into what exists and why our customers do what they do. We had some data insights from the BI team, Google Analytics, and specific experiments that was successful on mobile and web apps.</p>
        {/* <p>
          The goal was to help customers complete the checkout process. We had a workflow for the web app, made with components from the design system. We could use this same workflow and components for TextPay.
          <br />
          The location number was available from text messages and account information we could get from the phone number. It was only important for us to show the location number as confirmation and let it be corrected in case there was a typo in the SMS.
        </p> */}
        <ol>
          Data showed that we could divide customers into 2 groups:
          <li>Loyal users</li>
          <li>Users with 1–2 transactions</li>
        </ol>
        <p>
          It was important for us to convert users into loyal ones by offering them a mobile application. Increased mobile apps users would reduce the cost of reminders and could increase engagement.
          <br />
          The old version of TextPay offered to download the application at the last step, but the percentage of conversions was low. Why should I download the application if I have already done? We decided to suggest downloading the mobile application at the first contact. For those who skipped, further in the checkout, we offered $5 per wallet when downloading a mobile application (the idea came from marketing).
          <br />
          One more hypothesis: users with 1–2 transactions may be road trippers. For them, we could offer travel activities. Another update for road trippers is the addition of the Spanish language. There were also requests from the support for it. These were Peek Travel and Localize integrations.
        </p>
        <h3>Designing the solutions</h3>
        <p>
          I built the entire checkout process using components from the design system. As a result, the launch of the new version of TextPay took no more than a month.
          <br/>
          I fixed many UX issues, like dead-end scenarios, keyboard overlapping on important information, and lack of communication to users. For example: dead end scenario if the client used the old link. The user had to resend a text message, which was generally not obvious, and nothing could be done using the link itself anyway.
          <br/>
          It was important to consider that previously there were no branded locations. So, I've prepared the high fidelity prototypes for different branded locations to show and confirm with clients before upcoming updates.
        </p>
        <p>
          Then, I switched to CameraPay. Generally, the differences were only in the header and the first launch screen. Since I had already worked out all scenarios for TextPay, it turned out that CameraPay started with one of the previously worked out scenarios. As a result, the CameraPay launch was very fast and easy.
        </p>
      </Wrapper>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
        <div className="wide-scheme">
          <InnerImageZoom src={textpayCJM} zoomSrc={textpayCJM} />
        </div>
      </AnimationOnScroll>
      <Wrapper id="results">
        <strong className="sub-title">Outcomes</strong>
        <h3>Launch and build at scale</h3>
        <p>
          After a successful launch, I continued to design new integrations, which helped to continue the growth.
          The first integration was Localize, which helped to improve communication with non-English speaking users, launched on CameraPay and TextPay.
          <br />
          The next integrations were launched on primary products (TextPay, CameraPay, Web, iOS, and Android apps). It was possible to launch in a short time, because before we have reworked all platforms for flexible integrations. 
          <br />
          It always started with exploring the capabilities of the API with the development team. Then, I added new cross-platform components to the design system and expanded the existing ones. Next, I designed all the scenarios for possible tests, and refined them.
          <br/>
          Next integrations were with travel providers (direct and Peek Travel), condo hotels, insurance (Neosurance), and enforcement.
        </p>
      </Wrapper>
      <div className="screens-grid">
        <div className="col">
          <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
            <img src={screen5} alt="No License Plate" />
            <img src={screen2} alt="Saved Vehicle" />
            <img src={screen9} alt="Branded Location" />
          </AnimationOnScroll>
        </div>
        <div className="col">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
            <img src={screen10} alt="Travel" />
            <img src={screen6} alt="Auth" />
            <img src={screen3} alt="Space Type" />
          </AnimationOnScroll>
        </div>
        <div className="col">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
            <img src={screen4} alt="Insurance" />
            <img src={screen7} alt="License Plate Detection" />
            <img src={screen11} alt="Review and Pay" />
          </AnimationOnScroll>
        </div>
        <div className="col">
          <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
            <img src={screen8} alt="Choose Space Type" />
            <img src={screen1} alt="App Suggestion" />
            <img src={screen12} alt="Success" />
          </AnimationOnScroll>
        </div>
      </div>
      <Projects project="textpay" />
    </AnimationOnScroll>
  <Footer />
</Layout>
  )
}

const Header = styled.div`
  background: #D7262E;
  padding: 100px 0;
  text-align: center;
  position: relative;

  .gatsby-image-wrapper {
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 0 0 28px 8px rgb(0 0 0 / 10%);
    position: relative;
    z-index: 9;
  }

  .ep {
    position: absolute;
    top: 0;
    right: 30px;
    max-height: 50%;
    width: 19%;
  }

  .lp {
    position: absolute;
    bottom: 0;
    right: 17%;
    max-height: 50%;
    width: 19%;
  }

  .pp-logo {
    position: absolute;
    right: 0;
    top: 43%;
    max-width: 50%;
  }

  .tp-logo {
    left: 50px;
    position: absolute;
    top: 50%;
    margin-top: -150px;
    max-width: 50%;
  }

  svg {
    max-width: 100%;
  }

  .camera {
    width: 19%;
    height: auto;
    margin: auto;
    z-index: 9;
  }

  @media (max-width: 1024px) {
    padding: 50px 0;

    .tp-logo {
      left: 10px;
    }
  }

  @media (max-width: 550px) {
    padding: 20px 0;
  }

  @media (max-width: 400px) {
    padding: 10px 0;
    
    .tp-logo {
      left: 0;
    }
  }
`

export default IndexPage
